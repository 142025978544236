@import "../../responsive";

.Quote {
  &__personas {
    margin: 0.5rem 0 0 0;
    display: block;
    text-align: center;
    &__profilesList {
      &__persona {
        border-radius: 0.2rem;
        background: #e5e5e5;
        margin: 0rem 0 -1rem 0;
        padding: 1.4rem;
        @include breakpoint($tablet-width) {
          margin: 0.5rem 0 0;
          padding: 1.7rem 1.5rem;
        }
        &__profile {
          padding: 0 0 0.2rem;
          border-radius: 0.2rem;
          line-height: 1rem;
          margin: 0 0.5rem 0.2rem;
          text-align: left;
          display: block;
          overflow: hidden;
          display: flex;
          align-items: center;
          color: rgba(black, 0.9);
          &__image {
            border-radius: 0.2rem;
            width: 4.5rem;
            @include breakpoint($large-phone-width) {
              width: 3rem;
            }
            margin: 0 0.7rem 0 0;
            box-shadow: 0 0.5rem 0.5rem rgba(black, 0.05);
          }
          &__container {
          }
          &__name {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.6rem;
            padding-right: 0.5rem;
            font-family: "IBM Plex Mono", monospace;
          }
          &__location {
            display: block;
            font-size: 0.85rem;
            font-weight: 400;
            color: rgba(black, 0.4);
            font-family: "IBM Plex Mono", monospace;
          }
        }
        &__detailsList {
          margin: 0rem auto;
          width: 100%;
          @include breakpoint($large-phone-width) {
            display: flex;
          }
          &__item {
            @include breakpoint($large-phone-width) {
              flex-grow: 1;
              flex-basis: 0;
            }
            text-align: center;
            background: white;
            color: #2c2c2c;
            box-shadow: 0 0.0625rem 0.0625rem rgba(black, 0.1);
            padding: 0.7rem 1rem 1rem;
            border-radius: 0.2rem;
            line-height: 1rem;
            margin: 0.4rem 0.4rem;
            display: block;
            &__wrapper {
              white-space: nowrap;
              display: inline-flex;
              align-items: center;
              margin: 0 auto;
              text-align: left;
              @include breakpoint($large-phone-width) {
                display: block;
                text-align: center;
              }
              &__image {
                width: 4rem;
                margin: 0;
                &--circle {
                  margin: 0.5rem 0 0.5rem;
                  width: 3rem;
                  border-radius: 100rem;
                }
              }
              &__container {
                margin-left: 0.5rem;
                width: 7rem;
                @include breakpoint($large-phone-width) {
                  width: auto;
                  margin-left: 0;
                }
                &__title {
                  font-size: 0.8rem;
                  font-weight: 500;
                  display: block;
                  opacity: 0.5;
                  text-transform: uppercase;
                  font-family: "IBM Plex Mono", monospace;
                  text-align: center;
                }
                &__item {
                  font-size: 0.9rem;
                  font-weight: 400;
                  display: block;
                  text-align: center;
                }
                &__itemDetail {
                  font-size: 0.85rem;
                  font-weight: 300;
                  display: block;
                  opacity: 0.6;
                  text-align: center;
                }
              }
            }
            &__title {
            }
          }
        }
      }
    }
  }
}
