@import "../../tokens.scss";
@import "../../responsive";

.project {
  background: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: center;
  align-items: center;
  padding-top: 1.5rem;
  background-size: 100% 180%;
  @include breakpoint($tablet-width) {
    padding-top: 3rem;
    min-height: none;
  }
  &.surface {
    background-image: url(surface.jpg);
  }
  &.login {
    flex-direction: row;
    text-align: center;
    form {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
    }
    .validation-message {
      font-size: 14px;
      text-align: center;
      margin: 16px 0 0;
    }
    .form {
      margin: 0 0 0 -32px;
      display: inline-flex;
      align-items: center;
      .back-arrow {
        display: inline-flex;
        align-items: center;
        margin: 0 0.5rem 1rem;
        &:hover {
          opacity: 0.8;
        }
      }

      .fields {
        display: inline-block;
        margin: 0 auto 1rem;
        white-space: nowrap;
        display: flex;
        border-radius: 0.5rem;
        box-shadow: $smoothLargeShadow;
        &.loading {
          opacity: 0.5;
        }

        input[type="password"] {
          border: 1px solid $grey-200;
          padding: 1rem 1.4rem;
          font-size: 1.1rem;
          border-radius: 0.5rem 0 0 0.5rem;
          height: 64px;
          width: 240px;
          box-sizing: border-box;
          display: inline-block;
          background: $white;
        }

        button {
          border: 1px solid $grey-200;
          border-left: 0;
          display: flex;
          align-items: center;
          height: 64px;
          box-sizing: border-box;
          padding: 0 1rem;
          border-radius: 0 0.5rem 0.5rem 0;
          text-align: center;
          font-weight: 400;
          background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(245, 245, 245, 0.67) 100%
            ),
            #ffffff;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .phones {
    margin: 0 auto 0;
    padding-bottom: 4rem;
    @include breakpoint($tablet-landscape-width) {
      display: flex;
    }
    .shim {
      width: 100%;
    }
    .device-wrapper {
      margin: 0 auto;
      flex-grow: 1;
      text-align: center;
      &.iphone {
        min-width: 312px;
        max-width: 312px;
        width: 312px;
      }
      &.ipad {
        text-align: center;
        min-width: 107.8%;
        margin-left: -2.9%;
      }
      > span {
        display: inline-flex;
        align-items: center;
        text-align: center;
        margin: 2rem auto 0.8rem;
        font-weight: 400;
        font-size: 16px;
        svg {
          margin-right: 8px;
        }
      }
      .iphone-12 {
        flex-grow: 0;
        min-width: 400px;
        min-height: 669px;
        max-width: 400px;
        max-height: 669px;
        width: 400px;
        height: 669px;
        margin: 0 auto;
        margin-left: -88px;
        padding-left: 88px;
        box-sizing: border-box;
        background: url(/iphone-12-grey.png);
        background-size: 100% 100%;
        .rh5v-DefaultPlayer_component {
          width: 276px;
          position: relative;
          top: 12px;
          margin: 0 auto 0;
          animation: fadeIn 500ms ease-in-out 500ms;
          animation-fill-mode: both;
          border-radius: 35px;
          .rh5v-Overlay_component,
          .rh5v-DefaultPlayer_video,
          .rh5v-Overlay_inner {
            border-radius: 35px;
            overflow: hidden;
          }
          .rh5v-DefaultPlayer_controls {
            opacity: 1;
            position: absolute;
            margin-bottom: -48px;
            background: transparent;
            .rh5v-PlayPause_component {
              display: none;
            }
            .rh5v-Seek_component {
              margin: 0 30%;
              .rh5v-Seek_track {
                box-shadow: $smoothLargeShadow;
              }
            }
          }
          &.hide-track .rh5v-DefaultPlayer_controls {
            opacity: 0;
          }
          * {
          }
        }
      }
      .ipad-pro {
        margin: 2rem 0 -6rem;
        width: 100%;
        padding-top: 86.3782%;
        background: url(/ipad-pro.png);
        background-size: 100% 100%;
        position: relative;
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 100;
          }
        }
        .rh5v-DefaultPlayer_component {
          position: absolute;
          width: 87%;
          border-radius: 1.5%;
          margin-left: 3%;
          left: 2.6%;
          top: 3.3%;
          animation: fadeIn 500ms ease-in-out 500ms;
          animation-fill-mode: both;
          .rh5v-Overlay_component,
          .rh5v-DefaultPlayer_video {
            border-radius: 1.5%;
            overflow: hidden;
          }
          .rh5v-Overlay_inner {
            border-radius: 35px;
            overflow: hidden;
          }
          .rh5v-DefaultPlayer_controls {
            opacity: 1;
            position: absolute;
            bottom: -40px;
            margin-bottom: -5%;
            background: transparent;
            .rh5v-PlayPause_component {
              display: none;
            }
            .rh5v-Seek_component {
              margin: 0 40%;
              .rh5v-Seek_track {
                box-shadow: $smoothLargeShadow;
              }
            }
          }
          &.hide-track .rh5v-DefaultPlayer_controls {
            opacity: 0;
          }
        }
      }
    }
  }
}
