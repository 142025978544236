@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,500|Rubik:300,400,500&display=swap");
@import "tokens.scss";
@import "./responsive";

* {
  outline: none;
}

html {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

@keyframes bg {
  0% {
    background-image: url(images/1.png);
  }
  9% {
    background-image: url(images/1.png);
  }
  10% {
    background-image: url(images/8.png);
  }
  19% {
    background-image: url(images/8.png);
  }
  20% {
    background-image: url(images/5.png);
  }
  29% {
    background-image: url(images/5.png);
  }
  30% {
    background-image: url(images/7.png);
  }
  39% {
    background-image: url(images/7.png);
  }
  40% {
    background-image: url(images/4.png);
  }
  49% {
    background-image: url(images/4.png);
  }
  50% {
    background-image: url(images/2.png);
  }
  59% {
    background-image: url(images/2.png);
  }
  60% {
    background-image: url(images/5.png);
  }
  69% {
    background-image: url(images/5.png);
  }
  70% {
    background-image: url(images/3.png);
  }
  79% {
    background-image: url(images/3.png);
  }
  80% {
    background-image: url(images/2.png);
  }
  89% {
    background-image: url(images/2.png);
  }
  90% {
    background-image: url(images/6.png);
  }
  99% {
    background-image: url(images/6.png);
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

body {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.8rem;
  background: $grey-100;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  // cursor: url("images/cursor.png"), url("/images/cursor.cur"), default !important;
}
// * {
//   cursor: url("images/cursor.png"), url("/images/cursor.cur"), default !important;
// }

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .bg-effect {
    animation: bg 1000ms;
    opacity: 0.7;
    animation-iteration-count: infinite;
    background-size: 60px 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

a {
  color: $black;
}

a.link {
  border-bottom: 2px solid $grey-400;
}

.shim-1 {
  flex-shrink: 0;
  width: 0.125rem;
}

.shim-2 {
  flex-shrink: 0;
  width: 0.25rem;
}

.shim-3 {
  flex-shrink: 0;
  width: 0.5rem;
}

.shim-4 {
  flex-shrink: 0;
  width: 0.75rem;
}

.shim-5 {
  flex-shrink: 0;
  width: 1rem;
}

.shim-6 {
  flex-shrink: 0;
  width: 1.25rem;
}

.shim-7 {
  flex-shrink: 0;
  width: 1.5rem;
}

.shim-8 {
  flex-shrink: 0;
  width: 2.25rem;
}

.shim-9 {
  flex-shrink: 0;
  width: 48px;
}
