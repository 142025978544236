@import "../../responsive";

.SmaatoUIFramework {
  .CaseStudy_magnifierContainer {
    .noMagnifierOpt {
      display: none;
      width: 100%;
      box-sizing: border-box;
      background: rgba(black, 0.1);
      padding: 0.5rem 1rem;
      @include breakpoint($tablet-width) {
        padding: 0;
        border-radius: 0.2rem;
      }
      cursor: zoom-in;
      &:hover {
        opacity: 0.92;
      }
    }
    .magnifierOpt {
      display: block;
      cursor: none;
      background: #f0f0f0;
      border-radius: 0.2rem;
      z-index: 20;
      position: relative;
      img {
        border-radius: 0.2rem;
      }
    }
    @media (any-hover: none) {
      .noMagnifierOpt {
        display: block;
      }
      .magnifierOpt {
        display: none;
        cursor: zoom-in;
      }
    }
  }
  .magnifying-glass {
    box-shadow: 0 0.125rem 3rem rgba(0, 0, 0, 0.2),
      0 0.125rem 0.3rem rgba(0, 0, 0, 0.1);
    background: rgba(#f0f0f0, 0.61);
    background-repeat: no-repeat;
    border-radius: 1rem;
  }
  .SmaatoUIFramework_wild {
    width: 100%;
    border-radius: 0.2rem;
  }
  .SmaatoUIFramework_sdx {
    margin: 0 1.4rem;
    display: block;
    box-sizing: border-box;
    @include breakpoint($tablet-width) {
      margin: 0;
    }
    img {
      width: 100%;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 0.2rem;
    }
  }
  .SmaatoUIFramework_frameworks {
    width: 100%;
    border-radius: 0.2rem;
    background: rgba(236, 236, 236, 0.9);
    margin: 0.5rem 0 0 0;
  }
  .SmaatoUIFramework_boxart {
    width: 100%;
    border-radius: 0.2rem;
    background: rgba(236, 236, 236, 0.9);
    margin: 0.5rem 0 0 0;
  }
  .SmaatoUIFramework_box {
    width: 30%;
    margin: 0.6rem 0 0.6rem;
    padding: 0 35%;
    background: linear-gradient(
        rgba(236, 236, 236, 0.9),
        rgba(236, 236, 236, 0.9)
      ),
      url(/projects/smaato-ui-framework/bg.jpg);
    background-size: 200% auto;
    background-position: -3.4rem -20rem;
    border-radius: 0.2rem;
  }
  .SmaatoUIFramework_files {
    text-align: center;
    padding: 2rem 0;
    img {
      width: 45%;
      display: inline-block;
    }
    width: 100%;
    border-radius: 0.2rem;
    background: rgba(236, 236, 236, 0.5);
    margin: 0.5rem 0 2rem 0;
  }
  .SmaatoUIFramework_heatmap img {
    width: 100%;
    border-radius: 0.2rem;
    margin: 0.5rem 0 0 0;
  }
  .SmaatoUIFramework_sketchfile {
    img {
      width: 100%;
    }
  }
}
