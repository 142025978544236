@import "../../responsive";

.CaseStudy_Beta {
  margin: 0.5rem 0 3.4rem 0;
  @include breakpoint($tablet-width) {
    display: flex;
    align-items: center;
  }
  .CaseStudy_Beta_content {
    order: 1;
  }
  .CaseStudy_Beta_image {
    order: 0;
    flex-grow: 1;
    border-radius: 0.2rem;
    margin: 0.5rem 1.4rem 0.5rem;
    width: 13rem;
    padding: 1rem;
    @include breakpoint($tablet-width) {
      min-width: 12rem;
      padding: 3%;
      margin: 0.5rem 2rem 0.5rem 0;
    }
    @include breakpoint($small-width) {
      min-width: 14rem;
    }
    @include breakpoint($large-width) {
      min-width: 15rem;
    }
    background: rgba(0, 0, 0, 0.03);
  }
}
