@import "../../responsive";

.CaseStudy_Workflow {
  margin: 1.4rem 0;
  font-size: 0.8rem;
  text-align: center;
  @include breakpoint($tablet-width) {
    margin-left: 0;
    margin-right: 0;
    font-size: 0.9rem;
  }
  .CaseStudy_Workflow_row {
    display: flex;
    min-width: 240px;
    margin-right: 0.8rem;
    .CaseStudy_Workflow_cell {
      &.content {
        width: 21%;
      }
      &.spacer {
        width: 4%;
        display: flex;
        flex-direction: column;
      }
      .topSpace {
        height: 42px;
        display: block;
        position: relative;
      }
      .middleSpace {
        flex-grow: 1;
        display: block;
        position: relative;
      }
      .bottomSpace {
        height: 32px;
        display: block;
        position: relative;
      }
      .CaseStudy_Workflow_tile {
        white-space: nowrap;
        border-radius: 0.2rem;
        display: block;
        text-align: center;
        overflow: hidden;
        height: 5.3rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        line-height: 10px;
        @include breakpoint($large-phone-width) {
          height: 6rem;
        }
        @include breakpoint($tablet-width) {
          padding-left: 0;
          padding-right: 0;
          height: 12rem;
        }
        @include breakpoint($tablet-landscape-width) {
          height: 14rem;
        }
        white-space: normal;
        min-width: 10px;
        position: relative;
        &.empty {
          position: relative;
          padding: 1px 0;
        }
        &:not(.empty) {
          margin: 0;
          border-width: 2px;
          overflow: hidden;
          border: 0.0625rem solid rgba(0, 0, 0, 0.1);
          background: linear-gradient(rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));
          position: relative;
          z-index: 4;
        }
        &.empty {
          margin: 0;
        }
        .personaIcons {
          text-align: center;
          img {
            float: none;
            margin: 17px -2px 0;
            @include breakpoint($large-width) {
              margin: 20px -2px 4px;
            }
            width: 20%;
            padding: 5% 5%;
            border-radius: 100rem;
            position: relative;
            &.orange {
              background: linear-gradient(
                  rgba(255, 126, 28, 0.2),
                  rgba(255, 126, 28, 0.2)
                ),
                linear-gradient(white, white);
              border: 0.0625rem solid rgba(255, 126, 28, 0.4);
              z-index: 3;
            }
            &.blue {
              background: linear-gradient(
                  rgba(56, 234, 235, 0.2),
                  rgba(56, 234, 235, 0.2)
                ),
                linear-gradient(white, white);
              border: 0.0625rem solid rgba(56, 234, 235, 0.4);
            }
          }
        }
        p {
          font-size: 13px;
          &:not(.title) {
            display: none;
            @include breakpoint($tablet-width) {
              display: block;
            }
          }
          &.title {
            text-align: center;
            margin: 0;
            margin-top: 5px;
            @include breakpoint($tiny-width) {
              font-size: 0.65rem;
            }
          }
          line-height: 13px;
          @include breakpoint($tablet-width) {
            line-height: 16px;
            padding: 0 19px;
          }
          @include breakpoint($tablet-landscape-width) {
            line-height: 18px;
            padding: 0 19px;
          }
          @include breakpoint($small-width) {
            line-height: 20px;
          }
          strong {
            font-weight: 500;
            @include breakpoint($tablet-width) {
              font-weight: 400;
            }
          }
        }
      }

      $arrowColor: #bbbbbb;

      .curveDown {
        border: 1px solid $arrowColor;
        border-left: 0;
        border-bottom: 0;
        display: block;
        height: 13px;
        border-radius: 0 8px 0 0;
        position: relative;
        top: 27px;
        width: 18%;
        margin-left: 18%;
      }

      .line {
        width: 1px;
        height: 100%;
        background: $arrowColor;
        display: inline-block;
        position: absolute;
        top: 0;
        margin-left: 36%;
      }

      .empty {
        @include breakpoint($tablet-width) {
          text-align: left;
        }
      }

      .empty .line {
        margin-left: 0;
        @include breakpoint($tablet-width) {
          margin-left: 11%;
        }
      }

      .bottomSpace .lineLeft {
        margin-left: 50%;
        @include breakpoint($tablet-width) {
          margin-left: 11%;
        }
      }

      .line-outer {
        width: 1px;
        height: 100%;
        background: $arrowColor;
        display: inline-block;
        margin-left: 72%;
        position: absolute;
        top: 0;
      }

      .arrowDownRight {
        border: 1px solid $arrowColor;
        border-right: 0;
        border-top: 0;
        display: block;
        height: 28px;
        position: relative;
        top: 0;
        left: 18%;
        width: 50%;
        margin-left: 18%;
        border-radius: 0 0 0 8px;
        @include breakpoint($tablet-width) {
          width: 38%;
          &:after {
            // arrow point
            content: "";
            display: block;
            border: 1px solid $arrowColor;
            border-left: 0;
            border-top: 0;
            transform: rotate(-45deg);
            width: 8px;
            height: 8px;
            top: 24px;
            position: absolute;
            right: 0;
            bottom: auto;
            left: auto;
          }
        }
      }

      .curveUpLeft {
        border: 1px solid $arrowColor;
        border-right: 0;
        border-top: 0;
        display: block;
        height: 24px;
        border-radius: 0 0 0 8px;
        position: relative;
        top: 0;
        right: 0;
        margin-left: 50%;
        @include breakpoint($tablet-width) {
          margin-left: 11%;
        }
      }

      .curveLeftUp {
        border: 1px solid $arrowColor;
        border-left: 0;
        border-bottom: 0;
        display: block;
        width: 36%;
        height: 13px;
        border-radius: 0 8px 0 0;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-left: 0;
      }

      .curveUpRight {
        border: 1px solid $arrowColor;
        border-left: 0;
        border-top: 0;
        display: block;
        width: 36%;
        height: 28px;
        border-radius: 0 0 8px 0;
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
      }

      .curveDownRight {
        border: 1px solid $arrowColor;
        border-left: 0;
        border-bottom: 0;
        display: block;
        width: 72%;
        height: 28px;
        border-radius: 0 8px 0 0;
        position: relative;
        top: 28px;
        left: 0;
        margin-left: 0;
      }

      .curveDownLeft {
        border: 1px solid $arrowColor;
        border-left: 0;
        border-top: 0;
        display: block;
        width: 72%;
        height: 24px;
        border-radius: 0 0 8px 0;
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
      }

      .arrowLeft {
        background: $arrowColor;
        display: block;
        height: 1px;
        position: relative;
        top: 28px;
        margin-left: 28%;
        &:after {
          // arrow point
          content: "";
          display: block;
          border: 1px solid $arrowColor;
          border-right: 0;
          border-bottom: 0;
          transform: rotate(-45deg);
          width: 8px;
          height: 8px;
          top: -4px;
          position: absolute;
          left: 0;
        }
      }

      .arrowRight {
        background: $arrowColor;
        display: block;
        height: 1px;
        position: relative;
        top: 28px;
        @include breakpoint($tablet-width) {
          margin-right: 24%;
          &:after {
            // arrow point
            content: "";
            display: block;
            border: 1px solid $arrowColor;
            border-left: 0;
            border-top: 0;
            transform: rotate(-45deg);
            width: 8px;
            height: 8px;
            top: -4px;
            position: absolute;
            right: 0;
          }
        }
      }

      .arrowUp {
        display: inline-block;
        height: 100%;
        width: 1px;
        background: $arrowColor;
        position: relative;
        text-align: left;
        margin-left: 50%;
        top: 10px;
        height: 22px;
        &:after {
          // arrow point
          content: "";
          display: block;
          border: 1px solid $arrowColor;
          border-right: 0;
          border-bottom: 0;
          transform: rotate(45deg);
          width: 8px;
          height: 8px;
          top: 0;
          position: absolute;
          left: -4px;
        }
        @include breakpoint($tablet-width) {
          margin-left: 11%;
        }
      }

      .arrowDown {
        display: inline-block;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 6px;
        margin-left: 24px;
        text-align: left;
        &:before {
          content: "";
          display: block;
          border-left: 1px solid $arrowColor;
          border-top: 1px solid $arrowColor;
          border-radius: 8px 0 0 0;
          width: 8px;
          height: 150%;
          position: relative;
          right: 1px;
          top: -8px;
          @include breakpoint($tablet-width) {
            height: 28px;
          }
        }
        @include breakpoint($tablet-width) {
          height: 21px;
          &:after {
            // arrow point
            content: "";
            display: block;
            border: 1px solid $arrowColor;
            border-left: 0;
            border-top: 0;
            transform: rotate(-315deg);
            width: 8px;
            height: 8px;
            bottom: 0;
            position: absolute;
            left: -5px;
          }
        }
      }
      .lineDown {
        background: $arrowColor;
        display: inline-block;
        height: 16px;
        width: 1px;
        position: relative;
        margin-left: 22px;
        &:before {
          content: "";
          display: block;
          border-right: 1px solid $arrowColor;
          border-bottom: 1px solid $arrowColor;
          border-radius: 0 0 8px 0;
          width: 8px;
          height: 8px;
          bottom: -7px;
          position: absolute;
          margin-left: -8px;
        }
      }
      .bottomLine {
        background: $arrowColor;
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 7px;
        &.leftHalf {
          width: 14px;
        }
        &.rightHalf {
          left: 30px;
          right: 0;
        }
      }
      .horizontalLine {
        background: $arrowColor;
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 13px;
      }
    }
  }
}
