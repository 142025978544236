@import "../../responsive";

.CaseStudy_personas {
  width: 100%;
  @include breakpoint($small-width) {
    display: flex;
  }
  .CaseStudy_personas_persona {
    flex: 1;
    border-radius: 0.3rem;
    display: inline-block;
    font-size: 0.95rem;
    padding: 0.5rem 0;
    @include breakpoint($tablet-width) {
      padding-left: 0;
      padding-right: 0;
    }
    margin-right: 3rem;
    @include breakpoint($large-phone-width) {
      white-space: nowrap;
    }
    .CaseStudy_personas_persona_avatar {
      width: 4rem;
      padding: 0.8rem;
      @include breakpoint($large-phone-width) {
        width: 4rem;
        padding: 0.8rem;
      }
      @include breakpoint($small-width) {
        width: 3.5rem;
        padding: 1.1rem 0.7rem;
      }
      @include breakpoint($large-width) {
        width: 4rem;
        padding: 0.8rem 0.8rem 0.9rem;
      }
      border-radius: 0.3rem;
      vertical-align: middle;
      margin: 0.5rem 0.75rem 0.5rem 0;
      display: inline-block;
      &.orange {
        background: rgba(255, 126, 28, 0.2);
        border: 0.0625rem solid rgba(255, 126, 28, 0.4);
      }
      &.blue {
        background: rgba(56, 234, 235, 0.2);
        border: 0.0625rem solid rgba(56, 234, 235, 0.4);
      }
    }
    .CaseStudy_personas_persona_userStories {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      .CaseStudy_personas_persona_userStories_userStory {
        font-size: 1rem;
        display: block;
        strong {
          font-weight: 400;
        }
      }
      .CaseStudy_personas_persona_userStories_stories {
        margin: 0;
        line-height: 1.8rem;
        text-align: left;
        display: inline-block;
        white-space: nowrap;
        .CaseStudy_personas_persona_userStories_stories_number {
          background: rgba(0, 0, 0, 0.08);
          color: black;
          text-align: center;
          display: inline-block;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 100rem;
          margin-top: 0.17rem;
          margin-right: 0.3rem;
          line-height: 1.2rem;
          font-size: 0.8rem;
          font-weight: 400;
          vertical-align: top;
        }
        .CaseStudy_personas_persona_userStories_stories_story {
          display: inline-block;
          white-space: normal;
        }
      }
    }
  }
}
