@import "../../../tokens.scss";

.back-button {
  display: inline-flex;
  align-items: center;
  font-family: $mono-font;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: $grey-800;
  margin-left: -0.15rem;
  margin-bottom: 0.5rem;
  svg {
    margin-right: 2px;
  }
  &:hover {
    opacity: 0.8;
  }
}
