@import "../../responsive";

.CaseStudy_Wireframe {
  .CaseStudy_Wireframe_image img {
    width: 94%;
    margin: -1% 3% -5%;
    @include breakpoint($tablet-width) {
      width: 107.34%;
      margin: -1% -4.2% -5%;
    }
    &:hover {
      opacity: 0.9 !important;
    }
  }
}
