@import "../../responsive";
@import "../../tokens.scss";

.refunds {
  // background: url(/surface.jpg);
  // background-size: 100% 100%;
  // background-position: bottom;
  .refunds_image {
    img {
      width: 100%;
      border-radius: 0.25rem;
      @include breakpoint($tablet-width) {
        border-radius: 0.5rem;
      }
    }
  }
  .refunds_specimen {
    margin: 2rem 0 0;
    display: block;
    box-sizing: border-box;
    @include breakpoint($tablet-width) {
      margin: 2rem 0 0;
    }
    img {
      width: 100%;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 0.25rem;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px,
        rgba(17, 12, 46, 0.1) 0px 00px 10px 0px;
      @include breakpoint($tablet-width) {
        border-radius: 0.5rem;
      }
    }
  }
  strong {
    font-weight: 400;
  }
  ul {
    line-height: 1.4rem;
    margin-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    &.four-across {
      @include breakpoint($large-phone-width) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint($tablet-landscape-width) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &.three-across {
      @include breakpoint($tablet-landscape-width) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    li {
      font-size: 14px;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      background: white;
      padding: 1.5rem;
      box-sizing: border-box;
      border-radius: 0.25rem;
      text-align: center;
      .row {
        display: block;
        margin-bottom: 0.4rem;
        padding: 0.15rem 0 0;
        text-align: center;
      }
      svg {
        width: 48px;
        height: 48px;
        opacity: 0.65;
        margin-bottom: 0.65rem;
        stroke-width: 0.75px;
      }
      .title {
        font-size: 13px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-family: $mono-font;
        font-weight: 500;
      }
      span {
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        max-width: 245px;
      }
      .letter {
        display: none;
      }
      .rh5v-DefaultPlayer_component {
        border-radius: 8px;
        margin: 1rem auto 0.5rem;
        background: transparent;
        max-width: 250px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        *:not(video) {
          display: none;
        }
      }
    }
  }
  .refunds_burnit_container {
    text-align: center;
    position: relative;
    display: block;
    text-align: center;
    margin: 2rem 0;
    @include breakpoint($tablet-width) {
      float: right;
      margin: 0.25rem 0 1rem 2.5rem;
    }
    .screenshot {
      margin: 0 auto;
      display: inline-block;
    }
    .screenshot img {
      width: 100%;
      @include breakpoint($tablet-width) {
        width: 400px;
      }
      margin: 0 auto;
      box-shadow: rgba(0, 0, 0, 0.14) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      border-radius: 4px;
    }
    .fire {
      display: inline-block;
      img {
        position: absolute;
        bottom: -5%;
        margin-left: -3%;
        width: 106.5%;
        z-index: 3;
        height: 110%;
      }
    }
  }
  .refunds_demo {
    margin: 1.5rem 0 0;
    box-shadow: $smoothLargeShadowDark;
    border-radius: 9px;
    * {
      border-radius: 9px;
    }
    .rh5v-DefaultPlayer_component {
      border-radius: 8px;
      animation: fadeIn 500ms ease-in-out 500ms;
      animation-fill-mode: both;
      background: transparent;
      overflow: hideden;
      .rh5v-Overlay_component,
      .rh5v-DefaultPlayer_video {
        border-radius: 9px;
        overflow: hidden;
      }
      .rh5v-Overlay_inner {
        border-radius: 35px;
        overflow: hidden;
      }
      &.hide-track .rh5v-DefaultPlayer_controls {
        border-radius: 9px;
      }
    }
  }
}
