@import "../../tokens.scss";
@import "../../responsive";

@keyframes scrolltrack {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  1% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.project-browser-wrapper {
  background: $white;
  position: relative;
  z-index: 11;
}

.scrolltrack {
  animation: scrolltrack 100ms ease-in 300ms;
  animation-fill-mode: backwards;
  overflow: auto;
  text-align: center;
  width: 100vw;
  background: $grey-100;
  box-shadow: inset 0 -1px darken($grey-100, 5%);
  &::-webkit-scrollbar {
    display: none;
  }
}

.project-browser {
  display: flex;
  margin: 0 auto;
  width: 100%;
  li {
    box-sizing: border-box;
    flex-grow: 1;
    &:not(.selected) .project-browser-card {
      cursor: pointer;
      border-bottom: 1px solid $grey-200;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(245, 245, 245, 0.67) 100%
        ),
        #ffffff;
      &:active .project-browser-image {
        transform: scale(0.97);
      }
      &:hover {
        opacity: 0.9;
      }
    }
    &:last-child .project-browser-card {
      border-right: 1px solid $grey-300;
    }
    .project-browser-card {
      cursor: default;
      box-sizing: border-box;
      border-left: 1px solid $grey-300;
      display: block;
      padding: 0.5rem 0;
      width: 100%;
      min-width: 9.5rem;
      @include breakpoint($tablet-width) {
        min-width: 10.75rem;
      }
      height: 10rem;
      background: white;
      text-align: center;
      border-bottom: 1px solid $white;
      transition: all 50ms ease-in;
      .project-browser-image {
        transition: transform 25ms ease-in;
        height: 5rem;
        text-align: center;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin: 0.45rem auto 0;
        align-content: center;
        img {
          height: 100%;
        }
      }
      .project-browser-title {
        margin-top: -0.25rem;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 400;
      }
      .project-browser-subtitle {
        color: $grey-900;
        text-align: center;
        font-size: 0.6875rem;
        line-height: 100%;
      }
    }
  }
}
