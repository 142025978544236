$grey-100: #fafafa;
$grey-200: #d8d8d8;
$grey-300: #e2e8ee;
$grey-400: darken(#e2e8ee, 8%);
$grey-500: #8c8c8c;
$grey-600: #adadad;
$grey-700: darken(#adadad, 10%);
$grey-800: #757575;
$grey-900: #33343c;
$black: #171717;
$white: #ffffff;

$smoothLargeShadow: 0px 32px 64px rgba(0, 0, 0, 0.01),
  0px 16px 32px rgba(0, 0, 0, 0.01), 0px -16px 32px rgba(0, 0, 0, 0.01),
  0px 8px 16px rgba(0, 0, 0, 0.015), 0px 4px 8px rgba(0, 0, 0, 0.015),
  0px 2px 4px rgba(0, 0, 0, 0.025), 0px 1px 2px rgba(0, 0, 0, 0.025);
$smoothLargeShadowMedium: 0px 32px 64px rgba(0, 0, 0, 0.01),
  0px 16px 32px rgba(0, 0, 0, 0.01), 0px -16px 32px rgba(0, 0, 0, 0.01),
  0px 8px 16px rgba(0, 0, 0, 0.035), 0px 4px 8px rgba(0, 0, 0, 0.025),
  0px 2px 4px rgba(0, 0, 0, 0.045), 0px 1px 2px rgba(0, 0, 0, 0.045);
$smoothLargeShadowDark: 0px 64px 96px rgba(0, 0, 0, 0.1),
  0px 16px 32px rgba(0, 0, 0, 0.03), 0px 0.75px 16px rgba(0, 0, 0, 0.015);
$mono-font: "IBM Plex Mono";
