@import "../../tokens.scss";
@import "../../responsive.scss";

.projects-grid {
  margin-bottom: 3rem;
  li {
    display: block;
    box-sizing: border-box;
    padding: 0.75rem 0;
    cursor: pointer;
    overflow: hidden;
    @include breakpoint($tablet-width) {
      display: inline-block;
      width: 50%;
      &:nth-child(odd) {
        padding-right: 0.75rem;
      }
      &:nth-child(even) {
        padding-left: 0.75rem;
      }
    }
    @include breakpoint($small-width) {
      width: 33.3333%;
      &:nth-child(3n + 1) {
        padding-right: 0.75rem;
        padding-left: 0;
      }
      &:nth-child(3n + 2) {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
      &:nth-child(3n + 3) {
        padding-left: 0.75rem;
        padding-right: 0;
      }
    }
    .project-grid-card {
      display: block;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      padding: 1.5rem 2.25rem;
      background: white;
      border: 1px solid rgba($grey-200, 0.6);
      border-bottom: 1px solid $grey-400;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(245, 245, 245, 0.67) 100%
        ),
        #ffffff;
      text-align: center;
      box-shadow: $smoothLargeShadow;
      .project-grid-flag {
        opacity: 0.6;
        border-radius: 100px;
        font-weight: 500;
        font-family: $mono-font;
        text-transform: uppercase;
        font-size: 0.75rem;
        color: $black;
        svg {
          color: $black !important;
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
          bottom: -0.25rem;
          position: relative;
          opacity: 1;
        }
      }
      .project-grid-image {
        height: 14rem;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: -0.75rem auto -0.5rem;
        align-content: center;
        cursor: pointer;
        img {
          height: 80%;
          margin: 0 auto;
        }
      }
      .project-grid-title {
        text-align: center;
        font-weight: 400;
      }
      .project-grid-subtitle {
        font-size: 13px;
        color: $grey-500;
        text-align: center;
      }
    }
  }
}
