@import "../../responsive";

@keyframes page {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.CaseStudy {
  animation: page 200ms ease-out 100ms;
  animation-fill-mode: backwards;
  padding: 0 1.5rem 2rem;
  @include breakpoint($tablet-width) {
    padding: 0 3rem 5rem;
  }
  max-width: 63rem;
  // box-shadow: inset 0 0 0 3rem red;
  margin: 0 auto;
  p {
    margin: 0 0 1rem;
  }
  p a {
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  }
  .CaseStudy_exhibitHeader {
    padding: 1rem 1.4rem 1.2rem;
    @include breakpoint($tablet-width) {
      padding: 0.7rem 0;
    }
    line-height: 1.1rem;
    color: darken(#5c6565, 30%);
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0 0 0;
    font-family: "IBM Plex Mono", monospace;
    strong {
      font-weight: 500;
    }
  }
  .CaseStudy_cloud {
    display: none;
    padding: 1.5rem 1.4rem 0rem;
    @include breakpoint($tablet-width) {
      padding-left: 0;
      padding-right: 0;
    }
    cursor: default;
    li {
      display: inline-block;
      &.label {
        font-weight: 300;
        display: block;
        font-size: 0.9rem;
      }
      &:not(.label) {
        font-size: 0.85rem;
        padding: 0 0.5rem;
        line-height: 1.4rem;
        background: desaturate(darken(#f5fbfa, 5%), 10%);
        border-radius: 0.1rem;
        margin: 0 0.2rem 0 0;
      }
    }
  }
  .CaseStudy_back {
    margin-top: 0.8rem;
    margin-bottom: 1.5rem;
    a {
      color: #1c8cff;
      &:hover {
        text-decoration: underline;
      }
    }
    .CaseStudy_back_icon {
      width: 0.9rem;
      height: 0.75rem;
      display: inline-block;
      margin: 0 0.2rem 0 0;
      padding: 0 0 0 0.2rem;
      position: relative;
      &:before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        margin-top: -0.25rem;
        right: 0;
        border-color: #1c8cff;
        border-style: solid;
        border-width: 0;
        border-left-width: 0.0625rem;
        border-top-width: 0.0625rem;
        transform: rotate(-45deg);
      }
      &:after {
        content: "";
        width: 0.8em;
        position: absolute;
        height: 0.0625rem;
        display: block;
        left: 0.02rem;
        top: 50%;
        right: 0;
        background: #1c8cff;
      }
    }
  }
  @keyframes specimen {
    0% {
      opacity: 0;
    }
    1% {
      //transform: perspective(400px) rotateY(3deg);
      //left: 2rem;
      opacity: 0.2;
    }
    100% {
      //transform: perspective(0) rotateY(0);
      //left: 0;
      opacity: 1;
    }
  }
  .CaseStudy_specimen {
    margin: 1.2rem auto 0;
    position: relative;
    @include breakpoint($tablet-width) {
      margin-top: 2rem;
    }
    &__image {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.2);
      @include breakpoint($tablet-width) {
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-transform: translate(0px, 0);
        transform: translate(0px, 0);
        animation: specimen 0.5s;
        animation-fill-mode: both;
        //animation-delay: .5s;
      }
    }
  }
  .CaseStudy_device {
    margin: 0 1.4rem;
    display: block;
    text-align: center;
    box-sizing: border-box;
    //background: rgba(0, 0, 0, .06);
    border-radius: 0.2rem;
    @include breakpoint($tablet-width) {
      margin: 0;
    }
    &_image {
      width: 80%;
      padding: 0 10% 4rem;
      @include breakpoint($small-width) {
        width: auto;
        height: 60vh;
        padding: 0;
      }
    }
  }
  p {
    margin-top: 0;
    font-size: 1rem;
  }
  h1 {
    padding: 0 0 0;
    margin: 0;
    font-size: 24px;
    @include breakpoint($tablet-width) {
      padding-top: 1rem;
      padding-bottom: 0.8rem;
      padding-left: 0;
      padding-right: 0;
      font-size: 30px;
    }
    span {
      //display: imblock;
      //font-size: 1.4rem;
      position: relative;
      //top: -.2rem;
      color: rgba(black, 0.5);
      font-weight: 100;
    }
  }
  h2 {
    padding: 0.6rem 0;
    margin: 0 auto;
    font-size: 16px;
    line-height: 180%;
    @include breakpoint($tablet-width) {
      padding: 0 0 0.4rem;
      padding-left: 0;
      padding-right: 0;
      font-size: 18px;
    }
  }
  h4 {
    margin: 2rem 0 0;
    font-size: 1.2rem;
    font-weight: 400;
    @include breakpoint($tablet-width) {
      font-weight: 300;
      margin: 3rem 0 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0rem;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }
  h4 + h3 {
    margin-top: 1rem !important;
  }
  h3 {
    @include breakpoint($tablet-width) {
      padding-left: 0;
      padding-right: 0;
    }
    &.CaseStudy_stepTitle {
      margin-top: 2rem;
      font-family: "IBM Plex Mono", monospace;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      font-weight: 400;
      .CaseStudy_stepTitle_number {
        width: 1.5rem;
        font-size: 0.8rem;
        height: 1.5rem;
        margin-right: 0.4rem;
        line-height: 1.5rem;
        top: -0.1rem;
        position: relative;
        border-radius: 10rem;
        text-align: center;
        color: white;
        display: inline-block;
        background: black;
        font-family: "IBM Plex Mono", monospace;
      }
    }
  }
}

.zoomed,
body > div > div > img {
  display: none;
  @include breakpoint($tablet-width) {
    display: inherit;
    z-index: 100;
  }
}
