@import "../../responsive";

.CaseStudy_Prototype {
  margin: 0 1.4rem;
  @include breakpoint($tablet-width) {
    margin: 0;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
  }
  text-align: center;
  .CaseStudy_Prototype_videoContainer {
    @include breakpoint($tablet-width) {
      max-width: 50vw;
      margin: 0 auto;
      border-radius: 0.2rem;
    }
    .CaseStudy_Prototype_videoContainer_video {
      .video {
        padding: 72.49% 0 0 0;
        position: relative;
        border-radius: 0.2rem;
        .iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.2rem;
        }
      }
    }
  }
}
