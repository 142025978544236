@import "../../tokens.scss";
@import "../../responsive.scss";

.about {
  margin: 2rem 2rem 0;
  max-width: 860px;
  @include breakpoint($tablet-width) {
    margin: 5rem auto 0;
    padding: 0 2rem;
  }
  @include breakpoint($small-width) {
    margin: 5rem auto 0;
    padding: 0;
  }
  h1 {
    display: block;
    @keyframes typing {
      from {
        width: 0;
      }
    }
    @keyframes blink-caret {
      50% {
        border-color: $black;
      }
    }
    span {
      display: block;
      animation: typing 2s steps(21, end), blink-caret 0.5s step-end 8 alternate;
      width: 216px;
      overflow: hidden;
      border-right: 0.15rem solid transparent;
      white-space: nowrap;
    }
  }
  .flex {
    display: flex;
    flex-direction: column;
    * {
      text-align: center;
    }
    @include breakpoint($tablet-width) {
      flex-direction: row;
      align-items: stretch;
      * {
        text-align: left;
      }
    }
  }

  .me {
    margin: 0.75rem 0 -0.35rem;
    @include breakpoint($tablet-width) {
      margin: 0;
    }
    img {
      box-shadow: $smoothLargeShadow;
      display: inline-block;
      width: 100%;
      max-width: 22rem;
      box-sizing: border-box;
      @include breakpoint($tablet-width) {
        margin: 0;
        width: 18rem;
        height: 18rem;
      }
      @include breakpoint($tablet-landscape-width) {
        width: 20rem;
        height: 20rem;
      }
    }
  }

  .mobile {
    display: inline-block;
    margin: 0 auto;
    @include breakpoint($tablet-width) {
      display: none;
    }
  }

  .tablet {
    display: none;
    @include breakpoint($tablet-width) {
      display: block;
    }
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 400;
  }

  p {
    font-size: 1.125rem;
    line-height: 180%;
    margin-bottom: 1rem;
    @include breakpoint($tablet-landscape-width) {
      margin-bottom: 2rem;
    }
  }

  hr {
    margin: 2.625rem 0 1.8rem;
    box-sizing: border-box;
    border-bottom: 0.0625rem solid $grey-300;
    display: none;
    @include breakpoint($tablet-width) {
      display: block;
    }
  }

  .socials {
    display: inline-flex;
    margin: 0 auto 2rem;
    align-items: center;
    @include breakpoint($tablet-width) {
      margin: 0;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    a {
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 0.5rem 0 0;
      @include breakpoint($tablet-width) {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 1rem 0 0;
      }
      &:hover {
        opacity: 0.8;
      }
      &.resume {
        width: auto;
        background: $white;
        border: 1px solid $grey-200;
        padding: 0 0.75rem;
        border-radius: 100px;
        line-height: 220%;
        font-weight: 500;
        height: 1.7rem;
        font-family: "IBM Plex Mono";
        text-transform: uppercase;
        font-size: 12px;
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 0.2rem;
          margin-bottom: -0.25rem;
        }
        &:hover {
          box-shadow: $smoothLargeShadow;
          opacity: 1;
        }
      }
    }
  }
}
