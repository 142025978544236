@import "../../responsive";

.CaseStudy__UsabilityTesting {
  margin: 0 1.4rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  @include breakpoint($large-phone-width) {
    margin: 1.4rem;
    padding: 2rem;
  }
  @include breakpoint($tablet-width) {
    margin: 0;
  }
  &__image {
    border-radius: 0.2rem;
    width: 100%;
    box-sizing: border-box;
    max-width: 40rem;
    @include breakpoint($tablet-width) {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
