@import "../../responsive";

.Quote {
  .CaseStudy_magnifierContainer {
    .noMagnifierOpt {
      display: none;
      width: 100%;
      box-sizing: border-box;
      background: rgba(black, 0.1);
      padding: 0.5rem 1rem;
      @include breakpoint($tablet-width) {
        padding: 0;
        border-radius: 0.2rem;
      }
      cursor: zoom-in;
      &:hover {
        opacity: 0.92;
      }
    }
    .magnifierOpt {
      display: block;
      cursor: none;
      background: #f0f0f0;
      border-radius: 0.2rem;
      z-index: 20;
      position: relative;
    }
    @media (any-hover: none) {
      .noMagnifierOpt {
        display: block;
      }
      .magnifierOpt {
        display: none;
        cursor: zoom-in;
      }
    }
  }
  .magnifying-glass {
    box-shadow: 0 0.125rem 3rem rgba(0, 0, 0, 0.2),
      0 0.125rem 0.3rem rgba(0, 0, 0, 0.1);
    background: rgba(#f0f0f0, 0.61);
    background-repeat: no-repeat;
    border-radius: 1rem;
  }
  @keyframes one {
    0% {
      opacity: 0;
    }
    1% {
      //transform: perspective(400px) rotateY(5deg);
      //left: 2rem;
      opacity: 0.2;
    }
    100% {
      //transform: perspective(0) rotateY(0);
      //left: 0;
      opacity: 1;
    }
  }
  @keyframes two {
    0% {
      opacity: 0;
    }
    1% {
      //transform: perspective(400px) rotateY(5deg);
      opacity: 0.2;
    }
    100% {
      //transform: perspective(0) rotateY(0);
      opacity: 1;
    }
  }
  @keyframes three {
    0% {
      opacity: 0;
    }
    1% {
      //transform: perspective(400px) rotateY(5deg);
      //right: 2rem;
      opacity: 0.2;
    }
    100% {
      //transform: perspective(0) rotateY(0);
      //right: 0;
      opacity: 1;
    }
  }
  &__specimens {
    margin: 2rem;
    @include breakpoint($tablet-width) {
      margin: 2rem auto;
      display: flex;
    }
    &__specimen {
      flex-grow: 1;
      flex-basis: 0;
      transform: none;
      position: relative;
      padding-top: 178.0141844%;
      -webkit-transform: translate3d(0, 0, 0);
      @include breakpoint($tablet-width) {
        padding-top: 54.591%;
      }
      &:nth-child(1) {
        left: 0;
        @include breakpoint($tablet-width) {
          animation: one 0.5s;
          animation-fill-mode: both;
          //animation-delay: .5s;
        }
      }
      &:nth-child(2) {
        @include breakpoint($tablet-width) {
          animation: two 0.5s;
          animation-fill-mode: both;
          //animation-delay: .5s;
        }
        margin: 1rem 0;
        @include breakpoint($tablet-width) {
          margin: 0 4%;
        }
      }
      &:nth-child(3) {
        right: 0;
        @include breakpoint($tablet-width) {
          animation: three 0.5s;
          animation-fill-mode: both;
          //animation-delay: .5s;
        }
      }
      img {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.3rem;
      }
    }
  }
  &__main {
    background: rgba(0, 0, 0, 0.1);
    background: url(../../images/quote/dealership.jpg) center center;
    background-size: auto 100%;
    margin: 0 1.4rem;
    @include breakpoint($tablet-width) {
      background-size: 100% auto;
      margin: 0;
    }
    box-sizing: border-box;
    display: block;
    text-align: center;
    overflow: hidden;
    border-radius: 2rem;
    &__device {
      width: 30rem;
      height: 30rem;
      margin: 0 -8rem;
      background: url(../../images/quote/xsgold.png);
      background-size: 100% auto;
      display: inline-block;
    }
    .rh5v-DefaultPlayer_component {
      width: 36%;
      background: transparent;
      margin: 10% 0 0;
      display: inline-block;
    }
    .rh5v-Overlay_component {
      cursor: default;
    }
    video {
      border-radius: 5%;
    }
  }
  &__imageFull {
    display: block;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 0 0 0;
    padding: 0;
    @include breakpoint($tablet-width) {
      border-radius: 0.2rem;
    }
    &__image {
      width: 100%;
      border-radius: 0.2rem;
      margin: 0;
      padding: 0;
      display: block;
    }
  }
  &__image {
    background: rgba(0, 0, 0, 0.1);
    margin: 0 0 0 0;
    padding: 1rem 1.4rem;
    &__caption {
      text-align: center;
      display: block;
      font-size: 0.9rem;
      opacity: 0.7;
      padding: 0 0 1rem;
    }
    @include breakpoint($tablet-width) {
      padding: 2rem;
      border-radius: 0.2rem;
    }
    &__image {
      width: 100%;
    }
  }
}
