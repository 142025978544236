@import "../../responsive";

.CaseStudy_Launch_image {
  display: block;
  padding: 0 1.4rem;
  @include breakpoint($tablet-width) {
    padding: 0;
  }
  img {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 0.2rem;
    margin: 0.5rem 0 3.4rem;
  }
}
