@import "../../responsive";

.Quote {
  &__prototype {
    background-size: 100% 100%;
    margin: 0 0 1rem;
    padding-top: 0;
    display: block;
    text-align: center;
    overflow: hidden;
    position: relative;
    @include breakpoint($tablet-width) {
      border-radius: 0.2rem;
    }
    &__card {
      display: none;
      @include breakpoint($tablet-width) {
        display: inline-block;
      }
      background: white;
      padding: 10% 2rem;
      vertical-align: middle;
      border-radius: 0.2rem 0 0 0.2rem;
      position: relative;
      right: -41%;
      width: 6rem;
      box-shadow: 0 0 2rem rgba(black, 0.2);

      color: rgba(black, 0.9);
      &__image {
        width: 4rem;
        margin: 0;
        &--circle {
          margin: 0.5rem 0 0.5rem;
          width: 3rem;
          border-radius: 100rem;
        }
      }
      &__title {
        font-size: 0.8rem;
        font-weight: 500;
        display: block;
        opacity: 0.5;
        text-transform: uppercase;
        font-family: "IBM Plex Mono", monospace;
      }
      &__item {
        font-size: 0.9rem;
        font-weight: 400;
        display: block;
      }
      &__itemDetail {
        font-size: 0.85rem;
        font-weight: 300;
        display: block;
        opacity: 0.6;
      }
    }
    &--kierra {
      background-image: url(/projects/quote/persona-kierra-bg-mobile.jpg);
      background-size: auto 110%;
      background-position: 40% 100%;
      @include breakpoint($tablet-width) {
        background-image: url(/projects/quote/persona-kierra-bg.jpg);
        background-position: 30% 0%;
        background-size: auto 100%;
      }
      > [class*="__device"] {
        background-image: url(/projects/quote/iphone8gold.png);
        .rh5v-DefaultPlayer_component {
          left: 32.35%;
        }
        .rh5v-Overlay_component {
          box-shadow: inset -1px 0 0 #dfe1e1;
        }
      }
    }
    &--jessica {
      background-image: url(/projects/quote/persona-jessica-bg.jpg);
      background-size: 160% auto;
      background-position: 42% 100%;
      @include breakpoint($tablet-width) {
        background-image: url(/projects/quote/persona-jessica-bg.jpg);
        background-position: 100% 70%;
        background-size: auto 120%;
      }
      > [class*="__device"] {
        background-image: url(/projects/quote/pixel3a.png);
        .rh5v-DefaultPlayer_component {
          width: 33%;
          left: 33.5%;
        }
      }
    }
    &--david {
      background-image: url(/projects/quote/persona-david-bg.jpg);
      background-size: auto 100%;
      background-position: 40% 0%;
      @include breakpoint($tablet-width) {
        background-position: 30% 0%;
        background-size: 100% auto;
      }
      > [class*="__device"] {
        background-image: url(/projects/quote/iphone8black.png);
        .rh5v-DefaultPlayer_component {
          left: 32.25%;
        }
      }
    }
    &__device {
      width: 100%;
      padding-top: 100%;
      @include breakpoint($tablet-width) {
        width: 60%;
        padding-top: 60%;
      }
      background: url(/projects/quote/xsgold.png);
      background-size: 100% auto;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      right: -22%;
    }
    .rh5v-DefaultPlayer_component {
      width: 37%;
      background: transparent;
      display: inline-block;
      position: absolute;
      top: 16%;
      left: 32.5%;
    }
    .rh5v-Overlay_component {
      cursor: default;
    }
  }
}
